import React from 'react';

import { AppStoreButton } from '../icons/AppStoreButton';
import { PlayMarketButton } from '../icons/PlayMarketButton';

export enum StoreButtonVariant {
  APPSTORE = 'appstore',
  PLAY_MARKET = 'play-market',
}

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  href: string;
  variant: StoreButtonVariant;
}

export const StoreButton = (props: Props): JSX.Element => {
  const { variant, ...otherProps } = props;
  let ButtonIcon: () => JSX.Element;

  switch (variant) {
    default:
    case StoreButtonVariant.APPSTORE: {
      ButtonIcon = AppStoreButton;
      break;
    }

    case StoreButtonVariant.PLAY_MARKET: {
      ButtonIcon = PlayMarketButton;
      break;
    }
  }

  return (
    <a {...otherProps}>
      <ButtonIcon />
    </a>
  );
};
