import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { css, Global } from '@emotion/react';

import 'tailwindcss/tailwind.css';

import { font } from './constants/font';

import { App } from './App';

const queryClient = new QueryClient();

const Root = () => (
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <Global
        styles={css`
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: ${font.HEADING};
          }
        `}
      />

      <App />
    </React.StrictMode>
  </QueryClientProvider>
);

const render = () => {
  ReactDOM.render(<Root />, document.getElementById('root'));
};

render();
