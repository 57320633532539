import React from 'react';
import classnames from 'classnames';
import { css } from '@emotion/react';

export enum ButtonVariant {
  PRIMARY = 'primary',
  TERTIARY = 'tertiary',
}

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  href: string;
  variant?: ButtonVariant;
  icon?: boolean;
  rounded?: boolean;
}

export const Button = (props: Props): JSX.Element => {
  const { children, type, className, variant, icon, rounded, ...otherProps } = props;
  const commonClass = classnames(
    'py-3 px-5 font-bold flex justify-center items-center',
    rounded ? 'rounded-full' : '',
    className,
  );
  let variantClass: string;
  const iconCss = css`
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    padding: 0;
  `;

  switch (variant) {
    default:
    case ButtonVariant.PRIMARY: {
      variantClass = classnames(
        'bg-primary-500',
        'text-white',
        'hover:bg-primary-600',
        'active:bg-primary-400',
      );
      break;
    }

    case ButtonVariant.TERTIARY: {
      variantClass = classnames(
        'bg-mustard-500',
        'text-primary-500',
        'hover:bg-mustard-600',
        'active:bg-mustard-400',
      );
      break;
    }
  }

  return (
    <a
      className={classnames(commonClass, variantClass)}
      css={css`
        transition: all 0.3s ease;
        ${icon ? iconCss : ''}
      `}
      {...otherProps}
    >
      {children}
    </a>
  );
};

Button.defaultProps = {
  variant: ButtonVariant.PRIMARY,
  icon: false,
  rounded: true,
};
