import React from 'react';
import classnames from 'classnames';
import { css } from '@emotion/react';

import { StoreButton, StoreButtonVariant } from './StoreButton';

interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  imageSrc: string;
  imageAlt: string;
}

export const Banner = (props: Props): JSX.Element => {
  const { imageSrc, imageAlt, className, ...otherProps } = props;
  const wrapperClass = classnames(
    'w-full rounded-xlg overflow-hidden flex flex-col-reverse md:flex-row justify-end pr-10 md:pr-8 pl-10 md:pl-18 lg:pl-32 relative bg-primary-500',
    className,
  );

  return (
    <div
      className={wrapperClass}
      css={css`
        max-width: 920px;
        min-height: 320px;
      `}
      {...otherProps}
    >
      <div className="flex items-start md:items-end justify-center md:justify-end relative w-full mr-0 md:mr-10 min-h-48 max-w-none md:max-w-xs">
        <img src={imageSrc} alt={imageAlt} />
      </div>
      <div
        className="pt-8 md:pt-18 pb-8 md:pb-20 z-10 text-center md:text-left"
        css={css`
          @media (min-width: theme('screens.md')) {
            min-width: 400px;
          }
        `}
      >
        <h3 className="text-white text-xl md:text-2xl font-bold mb-4">
          Take control of your reproductive health. Download Bastion now
        </h3>
        <span className="text-white font-medium text-sm mb-8">
          Fertility, Prostate Health and E.D. specialists
        </span>
        <div className="flex justify-center md:justify-start mt-8">
          <StoreButton
            variant={StoreButtonVariant.APPSTORE}
            href="https://apps.apple.com/us/app/bastion-mens-health-clinic/id1521902247"
            className="mr-4"
          />
          <StoreButton
            variant={StoreButtonVariant.PLAY_MARKET}
            href="https://play.google.com/store/apps/details?id=com.getbastion.app"
          />
        </div>
      </div>

      <div
        className="hidden md:block absolute rounded-full z-0 bg-primary-600"
        css={css`
          top: -94px;
          right: -77px;
          width: 334px;
          height: 334px;
        `}
      />
    </div>
  );
};
