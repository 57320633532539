import React from 'react';

export const Instagram = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.60001 5.2C1.60001 4.35131 1.93715 3.53737 2.53726 2.93726C3.13738 2.33714 3.95131 2 4.80001 2H14.4C15.2487 2 16.0626 2.33714 16.6627 2.93726C17.2629 3.53737 17.6 4.35131 17.6 5.2V14.8C17.6 15.6487 17.2629 16.4626 16.6627 17.0627C16.0626 17.6629 15.2487 18 14.4 18H4.80001C3.95131 18 3.13738 17.6629 2.53726 17.0627C1.93715 16.4626 1.60001 15.6487 1.60001 14.8V5.2ZM4.80001 3.6C4.37566 3.6 3.96869 3.76857 3.66864 4.06863C3.36858 4.36869 3.20001 4.77565 3.20001 5.2V14.8C3.20001 15.2243 3.36858 15.6313 3.66864 15.9314C3.96869 16.2314 4.37566 16.4 4.80001 16.4H14.4C14.8244 16.4 15.2313 16.2314 15.5314 15.9314C15.8314 15.6313 16 15.2243 16 14.8V5.2C16 4.77565 15.8314 4.36869 15.5314 4.06863C15.2313 3.76857 14.8244 3.6 14.4 3.6H4.80001ZM9.60001 7.6C8.96349 7.6 8.35304 7.85286 7.90295 8.30294C7.45286 8.75303 7.20001 9.36348 7.20001 10C7.20001 10.6365 7.45286 11.247 7.90295 11.6971C8.35304 12.1471 8.96349 12.4 9.60001 12.4C10.2365 12.4 10.847 12.1471 11.2971 11.6971C11.7472 11.247 12 10.6365 12 10C12 9.36348 11.7472 8.75303 11.2971 8.30294C10.847 7.85286 10.2365 7.6 9.60001 7.6ZM5.60001 10C5.60001 8.93913 6.02143 7.92172 6.77158 7.17157C7.52173 6.42143 8.53914 6 9.60001 6C10.6609 6 11.6783 6.42143 12.4284 7.17157C13.1786 7.92172 13.6 8.93913 13.6 10C13.6 11.0609 13.1786 12.0783 12.4284 12.8284C11.6783 13.5786 10.6609 14 9.60001 14C8.53914 14 7.52173 13.5786 6.77158 12.8284C6.02143 12.0783 5.60001 11.0609 5.60001 10ZM14 6.8C14.3183 6.8 14.6235 6.67357 14.8485 6.44853C15.0736 6.22348 15.2 5.91826 15.2 5.6C15.2 5.28174 15.0736 4.97652 14.8485 4.75147C14.6235 4.52643 14.3183 4.4 14 4.4C13.6817 4.4 13.3765 4.52643 13.1515 4.75147C12.9264 4.97652 12.8 5.28174 12.8 5.6C12.8 5.91826 12.9264 6.22348 13.1515 6.44853C13.3765 6.67357 13.6817 6.8 14 6.8Z"
    />
  </svg>
);
