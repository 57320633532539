import React from 'react';

export const Facebook = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.1998 5.2C2.1998 4.35131 2.53694 3.53737 3.13706 2.93726C3.73717 2.33714 4.55111 2 5.3998 2H14.9998C15.8485 2 16.6624 2.33714 17.2625 2.93726C17.8627 3.53737 18.1998 4.35131 18.1998 5.2V14.8C18.1998 15.6487 17.8627 16.4626 17.2625 17.0627C16.6624 17.6629 15.8485 18 14.9998 18H5.3998C4.55111 18 3.73717 17.6629 3.13706 17.0627C2.53694 16.4626 2.1998 15.6487 2.1998 14.8V5.2ZM5.3998 3.6C4.97545 3.6 4.56849 3.76857 4.26843 4.06863C3.96837 4.36869 3.7998 4.77565 3.7998 5.2V14.8C3.7998 15.2243 3.96837 15.6313 4.26843 15.9314C4.56849 16.2314 4.97545 16.4 5.3998 16.4H10.1998V10.8H9.3998C9.18763 10.8 8.98414 10.7157 8.83411 10.5657C8.68408 10.4157 8.5998 10.2122 8.5998 10C8.5998 9.78783 8.68408 9.58434 8.83411 9.43431C8.98414 9.28429 9.18763 9.2 9.3998 9.2H10.1998V8C10.1998 7.25739 10.4948 6.5452 11.0199 6.0201C11.545 5.495 12.2572 5.2 12.9998 5.2H13.4798C13.692 5.2 13.8955 5.28429 14.0455 5.43431C14.1955 5.58434 14.2798 5.78783 14.2798 6C14.2798 6.21217 14.1955 6.41566 14.0455 6.56569C13.8955 6.71571 13.692 6.8 13.4798 6.8H12.9998C12.8422 6.8 12.6862 6.83104 12.5406 6.89134C12.395 6.95165 12.2627 7.04004 12.1513 7.15147C12.0398 7.2629 11.9514 7.39519 11.8911 7.54078C11.8308 7.68637 11.7998 7.84241 11.7998 8V9.2H13.4798C13.692 9.2 13.8955 9.28429 14.0455 9.43431C14.1955 9.58434 14.2798 9.78783 14.2798 10C14.2798 10.2122 14.1955 10.4157 14.0455 10.5657C13.8955 10.7157 13.692 10.8 13.4798 10.8H11.7998V16.4H14.9998C15.4241 16.4 15.8311 16.2314 16.1312 15.9314C16.4312 15.6313 16.5998 15.2243 16.5998 14.8V5.2C16.5998 4.77565 16.4312 4.36869 16.1312 4.06863C15.8311 3.76857 15.4241 3.6 14.9998 3.6H5.3998Z"
    />
  </svg>
);
