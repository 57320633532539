import React from 'react';

import { BastionLogo } from '../icons/BastionLogo';
import { Twitter } from '../icons/Twitter';
import { Instagram } from '../icons/Instagram';
import { Facebook } from '../icons/Facebook';
import { Linkedin } from '../icons/Linkedin';
import { Button, ButtonVariant } from './Button';
import { Section, SectionType } from './Section';

export const Footer = (): JSX.Element => (
  <Section type={SectionType.DARK} className="pt-10 pb-18">
    <div className="mb-4">
      <BastionLogo />
    </div>
    <span className="text-white mb-10 font-medium text-center">
      Bastion is the first at-home, comprehensive reproductive wellness solution for men.
    </span>
    <Button className="mb-4" href="https://getbastion.com/" variant={ButtonVariant.TERTIARY}>
      Learn more about Bastion
    </Button>
    <p className="text-white text-xs text-center mb-6">
      Don’t wait to improve your health. download
      <br />
      Bastion and schedule a visit with a doctor
    </p>

    <div className="flex">
      <Button className="mr-2" href="https://www.linkedin.com/company/getbastion/" icon>
        <Linkedin />
      </Button>
      <Button className="mr-2" href="https://www.facebook.com/Bastionhealth" icon>
        <Facebook />
      </Button>
      <Button className="mr-2" href="https://twitter.com/bastionhealth?s=21" icon>
        <Twitter />
      </Button>
      <Button className="mr-2" href="https://www.instagram.com/bastionhealth/" icon>
        <Instagram />
      </Button>
    </div>
  </Section>
);
