import React from 'react';
import classnames from 'classnames';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import { Button, ButtonVariant } from './Button';
import { Linkedin } from '../icons/Linkedin';
import { Facebook } from '../icons/Facebook';
import { Twitter } from '../icons/Twitter';

interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  direction?: 'row' | 'col';
  gap?: number;
}

export const ShareButtons = (props: Props): JSX.Element => {
  const { title, direction, gap, ...otherProps } = props;

  return (
    <div className={`flex flex-${direction}`} {...otherProps}>
      <div className={`p-${gap}`}>
        <LinkedinShareButton url={window.location.href} className="outline-none focus:outline-none">
          <Button href="#" icon variant={ButtonVariant.TERTIARY}>
            <Linkedin />
          </Button>
        </LinkedinShareButton>
      </div>
      <div className={`p-${gap}`}>
        <FacebookShareButton
          url={window.location.href}
          quote={title}
          className="outline-none focus:outline-none"
        >
          <Button href="#" icon variant={ButtonVariant.TERTIARY}>
            <Facebook />
          </Button>
        </FacebookShareButton>
      </div>
      <div className={`p-${gap}`}>
        <TwitterShareButton
          url={window.location.href}
          title={title}
          className="outline-none focus:outline-none"
        >
          <Button href="#" icon variant={ButtonVariant.TERTIARY}>
            <Twitter />
          </Button>
        </TwitterShareButton>
      </div>
    </div>
  );
};

ShareButtons.defaultProps = {
  direction: 'row',
  gap: 2,
};
