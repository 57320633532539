import React from 'react';
import classnames from 'classnames';

export enum AvatarSizes {
  SMALL = 'sm',
}

interface AvatarProps {
  src: string;
  size: AvatarSizes;
  alt?: string;
}

function getSizesClass(size: AvatarSizes): string {
  switch (size) {
    case AvatarSizes.SMALL: {
      return classnames('w-14', 'h-14');
    }

    default: {
      return '';
    }
  }
}

export const Avatar = (props: AvatarProps): JSX.Element => {
  const { src, alt, size } = props;

  const sizeClass = getSizesClass(size);
  const avatarWrapperClass = classnames('rounded-full', 'overflow-hidden', sizeClass);
  const avatarClass = classnames('w-full', 'h-full', 'object-cover');

  return (
    <div className={avatarWrapperClass}>
      <img className={avatarClass} src={src} alt={alt || ''} />
    </div>
  );
};

Avatar.defaultProps = {
  alt: '',
};
