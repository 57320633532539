import axios, { AxiosInstance } from 'axios';
import { api } from 'constants/app';
import { ApiRequestOptions, ApiResponse } from './types';

class Client {
  private _axios: AxiosInstance;

  constructor(apiUrl: string) {
    if (!apiUrl) {
      throw new Error('You need to specify "apiUrl"');
    }

    this._axios = this.initializeAxiosInstance(apiUrl);
  }

  /**
   * Makes a request using the GET method to the API
   * @param endpoint - Requesting endpoint
   * @param options - Request options
   */
  public async get<T>(endpoint: string, options?: ApiRequestOptions): Promise<T> {
    const response = await this._axios.get<ApiResponse<T>>(endpoint, {
      params: options?.params,
    });

    return response.data.data;
  }

  /**
   * Makes a request using the POST method to the API
   * @param endpoint - Requesting endpoint
   * @param body - Request body
   * @param accessToken - Access token (for protected endpoints)
   * @param params - Query params
   */
  public async post<T, B extends object = any>(
    endpoint: string,
    options?: ApiRequestOptions<B>,
  ): Promise<T> {
    const response = await this._axios.post<ApiResponse<T>>(endpoint, options?.body, {
      params: options?.params,
    });

    return response.data.data;
  }

  /**
   * Initialize axios instance
   * @param baseUrl - Base URL of the API
   */
  private initializeAxiosInstance(baseUrl: string): AxiosInstance {
    const client = axios.create({
      baseURL: baseUrl,
      headers: { 'Content-Type': 'application/json' },
    });

    return client;
  }
}

export const client = new Client(api);
