import React from 'react';

interface Props {
  text?: string;
}

export const Empty = (props: Props): JSX.Element => {
  const { text } = props;

  return (
    <div className="fixed w-full h-full flex items-center justify-center bg-primary-500">
      <span className="font-serif font-bold text-3xl text-mustard-500">{text}</span>
    </div>
  );
};

Empty.defaultProps = { text: 'Article not found' };
