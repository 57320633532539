import React, { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import classnames from 'classnames';
import colors from '../constants/colors.json';

export enum SectionType {
  DARK = 'dark',
  LIGHT = 'light',
  BACKGROUND_IMAGE = 'bg-img',
}

export enum SectionAlign {
  CENTER = 'center',
  START = 'start',
  END = 'end',
}

interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type?: SectionType;
  src?: string;
  horizontalAlign?: SectionAlign;
  verticalAlign?: SectionAlign;
  height?: string;
}

export const Section = (props: Props): JSX.Element => {
  const {
    children,
    type,
    src,
    horizontalAlign,
    verticalAlign,
    height,
    className,
    ...otherProps
  } = props;
  const wrapperClass = classnames('relative overflow-hidden px-5', className);
  const alignClass = classnames(`justify-${verticalAlign} items-${horizontalAlign}`);
  const sectionClass = classnames('container', 'mx-auto', 'h-full', 'flex', 'flex-col', alignClass);
  let cssOfWrapper: SerializedStyles;

  switch (type) {
    case SectionType.BACKGROUND_IMAGE: {
      cssOfWrapper = css`
        background-color: ${colors.primary[700]};
        z-index: 0;
        height: ${height};

        &:after {
          content: '';
          background-image: linear-gradient(
              180deg,
              ${colors.primary[700]} -6.5%,
              rgba(15, 36, 53, 0) 90.5%
            ),
            url(${src});
          background-size: cover;
          background-position: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          opacity: 0.5;
        }
      `;
      break;
    }

    case SectionType.DARK: {
      cssOfWrapper = css`
        background-color: ${colors.primary[600]};
        height: ${height};
      `;
      break;
    }

    default:
    case SectionType.LIGHT: {
      cssOfWrapper = css`
        height: ${height};
      `;
      break;
    }
  }

  return (
    <div className={wrapperClass} css={cssOfWrapper} {...otherProps}>
      <div className={sectionClass}>{children}</div>
    </div>
  );
};

Section.defaultProps = {
  type: SectionType.LIGHT,
  src: '',
  horizontalAlign: SectionAlign.CENTER,
  verticalAlign: SectionAlign.CENTER,
  height: 'auto',
};
