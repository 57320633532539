import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useQuery } from 'react-query';

import { Section } from './components/Section';
import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { Banner } from './components/Banner';
import bannerImage from './assets/iphones-bastion.png';
import { ContentfulApi } from './services/contentful';
import { Api } from './services/api';
import { Loader } from './components/Loader';
import { Header } from './components/Header';
import { Button, ButtonVariant } from './components/Button';
import { ShareButtons } from './components/ShareButtons';
import { Empty } from './components/Empty';

export const App = (): JSX.Element => {
  const shareToken = new URL(window.location.href).searchParams.get('token');
  const { data: entry, isError, isLoading } = useQuery('getInsight', async () => {
    const insightId = await Api().insight.parseShareToken(shareToken || '');
    return ContentfulApi.getEntryById(insightId);
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!entry || isError) {
    return <Empty />;
  }

  return (
    <div>
      <Header
        title={entry.title}
        authorName={entry.author}
        reviewerName={entry.reviewer}
        backgroundImage={entry.image}
        authorLogo={entry.authorLogo}
      />

      <Section className="mt-12 mb-20">
        <Content>
          <ReactMarkdown>{entry.content}</ReactMarkdown>
        </Content>

        <div className="flex flex-col items-center md:hidden mt-10">
          <span className="text-base font-medium">Share article:</span>
          <ShareButtons title={entry.title} gap={1} />
        </div>

        <Banner
          imageSrc={bannerImage}
          imageAlt="Bastion Health on iPhone"
          className="mt-10 md:mt-20"
        />
      </Section>

      <Footer />

      <Button
        href="#"
        variant={ButtonVariant.PRIMARY}
        rounded={false}
        className="block md:hidden fixed bottom-0 left-0 w-full z-50"
      >
        Download Bastion App
      </Button>
    </div>
  );
};
