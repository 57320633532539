import React from 'react';
import { css } from '@emotion/react';
import colors from 'constants/colors.json';

interface SeparatorProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  width: string;
  color?: string;
}

export const Separator = (props: SeparatorProps): JSX.Element => {
  const { width, color, ...defaultProps } = props;

  return (
    <div
      css={css`
        background-color: ${color};
        height: 2px;
        width: ${width};
      `}
      {...defaultProps}
    />
  );
};

Separator.defaultProps = { color: colors.mustard[500] };
