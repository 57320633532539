import React from 'react';
import classnames from 'classnames';
import { css } from '@emotion/react';

import { Avatar, AvatarSizes } from './Avatar';
import { font } from '../constants/font';

export enum AuthorType {
  LIGHT = 'light',
  DARK = 'dark',
}

interface AuthorProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  authorName: string;
  authorLogo?: string;
  reviewerName: string;
  type?: AuthorType;
}

export const Author = (props: AuthorProps): JSX.Element => {
  const { authorName, authorLogo, reviewerName, type } = props;
  let colorClass: string;

  switch (type) {
    case AuthorType.DARK: {
      colorClass = 'text-black';
      break;
    }

    default: {
      colorClass = 'text-white';
    }
  }

  const wrapperClass = classnames('flex', 'flex-col', 'items-center');

  return (
    <div className={wrapperClass}>
      {authorLogo && <Avatar size={AvatarSizes.SMALL} src={authorLogo} />}
      <p
        className={`mt-4 font-bold ${colorClass}`}
        css={css`
          font-family: ${font.HEADING};
        `}
      >
        By {authorName}
      </p>
      <p className={`opacity-60 ${colorClass}`}>
        Reviewed By: <span className="font-bold">{reviewerName}</span>
      </p>
    </div>
  );
};

Author.defaultProps = {
  type: AuthorType.LIGHT,
  authorLogo: '',
};
