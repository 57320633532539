import React from 'react';

export const AppStoreButton = (): JSX.Element => (
  <svg width="130" height="38" viewBox="0 0 130 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M128.681 33.689C128.681 35.6812 127.034 37.2948 124.996 37.2948H4.40855C2.37199 37.2948 0.718903 35.6812 0.718903 33.689V4.93377C0.718903 2.94257 2.37199 1.32324 4.40855 1.32324H124.995C127.034 1.32324 128.68 2.94257 128.68 4.93377L128.681 33.689Z"
      fill="black"
    />
    <path
      d="M124.607 1.36658C126.826 1.36658 128.632 3.12705 128.632 5.29055V33.3272C128.632 35.4907 126.826 37.2512 124.607 37.2512H4.79259C2.57362 37.2512 0.768013 35.4907 0.768013 33.3272V5.29055C0.768013 3.12705 2.57362 1.36658 4.79259 1.36658H124.607ZM124.607 0.617767H4.79259C2.15787 0.617767 0 2.72169 0 5.29055V33.3272C0 35.8961 2.15787 38 4.79259 38H124.607C127.242 38 129.4 35.8961 129.4 33.3272V5.29055C129.4 2.72169 127.242 0.617767 124.607 0.617767Z"
      fill="#A6A6A6"
    />
    <path
      d="M28.8781 19.1078C28.8503 16.0958 31.4076 14.6304 31.5246 14.5621C30.0763 12.5033 27.8314 12.222 27.0425 12.1996C25.1571 12.0061 23.3283 13.2996 22.3679 13.2996C21.3882 13.2996 19.9093 12.2183 18.3152 12.2501C16.264 12.2809 14.3451 13.4388 13.2926 15.2369C11.1206 18.9032 12.7405 24.2909 14.8214 27.2544C15.8624 28.7057 17.0787 30.3262 18.6708 30.2692C20.2284 30.2066 20.8103 29.301 22.6899 29.301C24.5523 29.301 25.0987 30.2692 26.7224 30.2328C28.3941 30.2066 29.4465 28.7749 30.451 27.3104C31.654 25.6469 32.1371 24.0086 32.1562 23.9245C32.1169 23.9114 28.9097 22.718 28.8781 19.1078Z"
      fill="white"
    />
    <path
      d="M25.8109 10.2501C26.6486 9.22864 27.2218 7.83896 27.0627 6.42871C25.8502 6.48105 24.3338 7.24645 23.4606 8.24549C22.688 9.12584 21.9979 10.5688 22.1761 11.9258C23.5382 12.0248 24.9367 11.2557 25.8109 10.2501Z"
      fill="white"
    />
    <path
      d="M51.4199 30.0611H49.2431L48.0507 26.4079H43.9061L42.7702 30.0611H40.6509L44.7572 17.624H47.2935L51.4199 30.0611ZM47.6913 24.8752L46.6129 21.6277C46.4989 21.2959 46.2851 20.5146 45.9698 19.2847H45.9314C45.8059 19.8137 45.6036 20.595 45.3256 21.6277L44.2665 24.8752H47.6913Z"
      fill="white"
    />
    <path
      d="M61.98 25.467C61.98 26.9922 61.5573 28.1978 60.7119 29.0828C59.9547 29.8706 59.0144 30.2641 57.892 30.2641C56.6804 30.2641 55.8101 29.8398 55.28 28.9912H55.2417V33.7154H53.1981V24.0455C53.1981 23.0867 53.1722 22.1026 53.1224 21.0933H54.9196L55.0337 22.5147H55.072C55.7535 21.4437 56.7878 20.9092 58.1757 20.9092C59.2607 20.9092 60.1665 21.3269 60.8912 22.1634C61.6177 23.0007 61.98 24.1016 61.98 25.467ZM59.8981 25.5399C59.8981 24.667 59.6969 23.9474 59.2924 23.3811C58.8505 22.7904 58.2572 22.4951 57.5133 22.4951C57.0092 22.4951 56.551 22.6596 56.1417 22.9839C55.7315 23.311 55.4631 23.7381 55.3375 24.267C55.2742 24.5138 55.2426 24.7156 55.2426 24.8745V26.3698C55.2426 27.0221 55.4477 27.5726 55.858 28.0221C56.2682 28.4716 56.8012 28.6959 57.4568 28.6959C58.2265 28.6959 58.8256 28.4062 59.254 27.8286C59.6834 27.2501 59.8981 26.4875 59.8981 25.5399Z"
      fill="white"
    />
    <path
      d="M72.5592 25.467C72.5592 26.9922 72.1365 28.1978 71.2901 29.0828C70.5339 29.8706 69.5935 30.2641 68.4711 30.2641C67.2595 30.2641 66.3892 29.8398 65.8601 28.9912H65.8218V33.7154H63.7782V24.0455C63.7782 23.0867 63.7523 22.1026 63.7025 21.0933H65.4997L65.6138 22.5147H65.6521C66.3327 21.4437 67.3669 20.9092 68.7558 20.9092C69.8399 20.9092 70.7457 21.3269 71.4722 22.1634C72.1959 23.0007 72.5592 24.1016 72.5592 25.467ZM70.4773 25.5399C70.4773 24.667 70.275 23.9474 69.8706 23.3811C69.4287 22.7904 68.8373 22.4951 68.0925 22.4951C67.5874 22.4951 67.1302 22.6596 66.7199 22.9839C66.3097 23.311 66.0422 23.7381 65.9167 24.267C65.8544 24.5138 65.8218 24.7156 65.8218 24.8745V26.3698C65.8218 27.0221 66.0269 27.5726 66.4352 28.0221C66.8455 28.4707 67.3784 28.6959 68.036 28.6959C68.8056 28.6959 69.4047 28.4062 69.8332 27.8286C70.2626 27.2501 70.4773 26.4875 70.4773 25.5399Z"
      fill="white"
    />
    <path
      d="M84.3872 26.573C84.3872 27.6309 84.0105 28.4916 83.2542 29.1561C82.4232 29.8822 81.2662 30.2448 79.7796 30.2448C78.407 30.2448 77.3066 29.9869 76.4736 29.4701L76.9472 27.8094C77.8443 28.3383 78.8287 28.6037 79.9013 28.6037C80.671 28.6037 81.2701 28.4337 81.7005 28.0953C82.1289 27.757 82.3427 27.3028 82.3427 26.7365C82.3427 26.2318 82.1663 25.8066 81.8126 25.4618C81.4608 25.1169 80.8732 24.7964 80.0528 24.5001C77.8194 23.688 76.7037 22.4983 76.7037 20.9338C76.7037 19.9114 77.0948 19.0731 77.8779 18.4208C78.6581 17.7676 79.6991 17.4414 81.0007 17.4414C82.1615 17.4414 83.1258 17.6386 83.8955 18.032L83.3846 19.6563C82.6657 19.275 81.8529 19.0844 80.9432 19.0844C80.2243 19.0844 79.6626 19.2572 79.2601 19.6012C78.9198 19.9086 78.7492 20.2834 78.7492 20.7273C78.7492 21.2189 78.9438 21.6254 79.3348 21.945C79.6751 22.2404 80.2933 22.56 81.1905 22.9048C82.288 23.3357 83.0941 23.8394 83.6127 24.4169C84.1293 24.9926 84.3872 25.7132 84.3872 26.573Z"
      fill="white"
    />
    <path
      d="M91.1438 22.5882H88.8913V26.9423C88.8913 28.0498 89.2881 28.603 90.0837 28.603C90.4489 28.603 90.7518 28.5722 90.9914 28.5105L91.048 30.0235C90.6454 30.1703 90.1153 30.2441 89.4588 30.2441C88.6517 30.2441 88.021 30.0039 87.5657 29.5245C87.1123 29.0441 86.8842 28.2385 86.8842 27.1068V22.5863H85.5423V21.0911H86.8842V19.449L88.8913 18.8584V21.0911H91.1438V22.5882Z"
      fill="white"
    />
    <path
      d="M101.307 25.5025C101.307 26.881 100.903 28.0127 100.096 28.8978C99.2492 29.809 98.1258 30.2631 96.7254 30.2631C95.3758 30.2631 94.3013 29.8267 93.5 28.9538C92.6986 28.081 92.298 26.9791 92.298 25.6511C92.298 24.2614 92.7102 23.1231 93.5374 22.2381C94.3626 21.3522 95.4764 20.9092 96.8768 20.9092C98.2264 20.9092 99.3124 21.3456 100.132 22.2194C100.916 23.0671 101.307 24.1614 101.307 25.5025ZM99.1869 25.567C99.1869 24.7399 99.0057 24.0306 98.6386 23.439C98.2101 22.7232 97.5976 22.3662 96.804 22.3662C95.9825 22.3662 95.3585 22.7241 94.9301 23.439C94.563 24.0315 94.3818 24.7521 94.3818 25.6044C94.3818 26.4315 94.563 27.1408 94.9301 27.7314C95.372 28.4473 95.9892 28.8043 96.7858 28.8043C97.566 28.8043 98.1785 28.4398 98.6204 27.7127C98.9971 27.11 99.1869 26.3932 99.1869 25.567Z"
      fill="white"
    />
    <path
      d="M107.95 22.8456C107.747 22.8091 107.532 22.7904 107.306 22.7904C106.587 22.7904 106.031 23.0549 105.64 23.5848C105.299 24.0521 105.129 24.6427 105.129 25.3558V30.0613H103.086L103.105 23.9175C103.105 22.8839 103.079 21.9428 103.029 21.0942H104.809L104.883 22.8101H104.94C105.156 22.2204 105.496 21.7456 105.962 21.3895C106.417 21.069 106.909 20.9092 107.439 20.9092C107.628 20.9092 107.798 20.9223 107.95 20.9456V22.8456Z"
      fill="white"
    />
    <path
      d="M117.089 25.1522C117.089 25.5092 117.065 25.8102 117.014 26.056H110.884C110.908 26.9419 111.204 27.6195 111.773 28.0867C112.29 28.5045 112.958 28.7138 113.778 28.7138C114.686 28.7138 115.514 28.5727 116.259 28.2895L116.579 29.6727C115.709 30.0428 114.681 30.2269 113.496 30.2269C112.069 30.2269 110.95 29.8175 110.135 28.9998C109.322 28.1821 108.915 27.084 108.915 25.7064C108.915 24.3541 109.294 23.228 110.052 22.3299C110.845 21.371 111.918 20.8916 113.268 20.8916C114.593 20.8916 115.597 21.371 116.278 22.3299C116.818 23.0915 117.089 24.0336 117.089 25.1522ZM115.14 24.6354C115.154 24.0448 115.021 23.5345 114.744 23.1037C114.39 22.5495 113.846 22.2729 113.115 22.2729C112.447 22.2729 111.904 22.543 111.489 23.085C111.148 23.5158 110.946 24.0326 110.884 24.6345H115.14V24.6354Z"
      fill="white"
    />
    <path
      d="M47.0156 9.97163C47.0156 11.0716 46.6773 11.8996 46.0015 12.4557C45.3756 12.9687 44.4861 13.2257 43.334 13.2257C42.7627 13.2257 42.2739 13.2015 41.8646 13.1529V7.14272C42.3985 7.05861 42.9736 7.01562 43.5947 7.01562C44.6922 7.01562 45.5194 7.24833 46.0773 7.71374C46.7022 8.23989 47.0156 8.99221 47.0156 9.97163ZM45.9565 9.99873C45.9565 9.28566 45.7629 8.73895 45.3756 8.35765C44.9884 7.97728 44.4229 7.78663 43.6781 7.78663C43.3618 7.78663 43.0924 7.80719 42.8691 7.85018V12.4192C42.9927 12.4379 43.219 12.4463 43.5477 12.4463C44.3165 12.4463 44.9098 12.2379 45.3277 11.8211C45.7456 11.4043 45.9565 10.7968 45.9565 9.99873Z"
      fill="white"
    />
    <path
      d="M52.6316 10.9328C52.6316 11.6103 52.4332 12.1655 52.0363 12.601C51.6203 13.0486 51.0692 13.272 50.381 13.272C49.7177 13.272 49.1895 13.058 48.7956 12.6281C48.4026 12.1991 48.2061 11.658 48.2061 11.0057C48.2061 10.3234 48.4083 9.76365 48.8147 9.32908C49.2212 8.89451 49.7675 8.67676 50.4557 8.67676C51.119 8.67676 51.652 8.89077 52.0555 9.31973C52.4389 9.73654 52.6316 10.2748 52.6316 10.9328ZM51.5897 10.9646C51.5897 10.558 51.4996 10.2094 51.3203 9.91878C51.1094 9.56739 50.8094 9.39169 50.4193 9.39169C50.0158 9.39169 49.709 9.56739 49.4982 9.91878C49.318 10.2094 49.2288 10.5636 49.2288 10.9823C49.2288 11.3888 49.3189 11.7374 49.4982 12.0281C49.7158 12.3795 50.0186 12.5552 50.4097 12.5552C50.7931 12.5552 51.0941 12.3767 51.3107 12.0187C51.4967 11.7225 51.5897 11.3711 51.5897 10.9646Z"
      fill="white"
    />
    <path
      d="M60.1616 8.7666L58.7478 13.1721H57.8276L57.242 11.2591C57.0934 10.7815 56.9726 10.3067 56.8787 9.83573H56.8605C56.7733 10.3198 56.6525 10.7937 56.4972 11.2591L55.8751 13.1721H54.9444L53.615 8.7666H54.6473L55.1582 10.8609C55.2818 11.3563 55.3834 11.8282 55.4649 12.2749H55.4831C55.5579 11.9067 55.6815 11.4376 55.856 10.8703L56.4972 8.76754H57.3158L57.9302 10.8254C58.0788 11.3273 58.1996 11.8105 58.2925 12.2759H58.3203C58.3884 11.8226 58.4909 11.3394 58.6271 10.8254L59.1753 8.76754H60.1616V8.7666Z"
      fill="white"
    />
    <path
      d="M65.3692 13.1728H64.3647V10.6495C64.3647 9.87197 64.0618 9.4832 63.4541 9.4832C63.156 9.4832 62.9154 9.58974 62.7285 9.80375C62.5435 10.0178 62.4496 10.2701 62.4496 10.5589V13.1719H61.4451V10.0262C61.4451 9.63927 61.4326 9.21965 61.4087 8.76546H62.2915L62.3384 9.45423H62.3662C62.4832 9.24021 62.6576 9.06358 62.8867 8.92246C63.1589 8.75798 63.4637 8.6748 63.7973 8.6748C64.219 8.6748 64.5698 8.80751 64.8488 9.07386C65.1958 9.40002 65.3692 9.88692 65.3692 10.5336V13.1728Z"
      fill="white"
    />
    <path d="M68.1395 13.1721H67.1359V6.74512H68.1395V13.1721Z" fill="white" />
    <path
      d="M74.0536 10.9328C74.0536 11.6103 73.8552 12.1655 73.4584 12.601C73.0424 13.0486 72.4903 13.272 71.803 13.272C71.1388 13.272 70.6106 13.058 70.2176 12.6281C69.8246 12.1991 69.6281 11.658 69.6281 11.0057C69.6281 10.3234 69.8304 9.76365 70.2368 9.32908C70.6432 8.89451 71.1896 8.67676 71.8768 8.67676C72.5411 8.67676 73.0731 8.89077 73.4776 9.31973C73.861 9.73654 74.0536 10.2748 74.0536 10.9328ZM73.0108 10.9646C73.0108 10.558 72.9207 10.2094 72.7414 9.91878C72.5315 9.56739 72.2305 9.39169 71.8414 9.39169C71.4369 9.39169 71.1301 9.56739 70.9202 9.91878C70.74 10.2094 70.6509 10.5636 70.6509 10.9823C70.6509 11.3888 70.741 11.7374 70.9202 12.0281C71.1378 12.3795 71.4407 12.5552 71.8318 12.5552C72.2152 12.5552 72.5152 12.3767 72.7318 12.0187C72.9187 11.7225 73.0108 11.3711 73.0108 10.9646Z"
      fill="white"
    />
    <path
      d="M78.9153 13.172H78.0134L77.9386 12.6645H77.9108C77.6021 13.0692 77.1622 13.272 76.5909 13.272C76.1644 13.272 75.8193 13.1383 75.5596 12.8729C75.3238 12.6318 75.2059 12.3318 75.2059 11.9757C75.2059 11.4374 75.4359 11.0272 75.8989 10.7431C76.3609 10.459 77.0107 10.3197 77.8475 10.3262V10.244C77.8475 9.66365 77.5351 9.37394 76.9091 9.37394C76.4634 9.37394 76.0704 9.48328 75.7311 9.7001L75.527 9.05712C75.9468 8.80386 76.4654 8.67676 77.0769 8.67676C78.2578 8.67676 78.8501 9.28422 78.8501 10.4991V12.1215C78.8501 12.5617 78.8722 12.9122 78.9153 13.172ZM77.8724 11.658V10.9786C76.7644 10.9599 76.2104 11.2561 76.2104 11.8664C76.2104 12.0963 76.2736 12.2683 76.403 12.3832C76.5324 12.4982 76.6973 12.5552 76.8938 12.5552C77.1143 12.5552 77.3203 12.4869 77.5082 12.3514C77.697 12.215 77.813 12.0421 77.8562 11.8299C77.8667 11.7823 77.8724 11.7243 77.8724 11.658Z"
      fill="white"
    />
    <path
      d="M84.623 13.1721H83.7316L83.6846 12.4646H83.6568C83.3722 13.0029 82.8871 13.2721 82.2056 13.2721C81.6612 13.2721 81.2078 13.0636 80.8484 12.6468C80.4889 12.23 80.3097 11.6889 80.3097 11.0244C80.3097 10.3114 80.5043 9.73383 80.8953 9.29272C81.274 8.88151 81.7379 8.67591 82.29 8.67591C82.8967 8.67591 83.3214 8.87497 83.5629 9.27403H83.5821V6.74512H84.5876V11.9852C84.5876 12.4141 84.5991 12.8094 84.623 13.1721ZM83.5821 11.3142V10.5796C83.5821 10.4525 83.5725 10.3497 83.5543 10.2712C83.4977 10.0357 83.376 9.83756 83.191 9.67775C83.0041 9.51794 82.7788 9.43757 82.5191 9.43757C82.1443 9.43757 81.851 9.58243 81.6353 9.87308C81.4216 10.1637 81.3133 10.5347 81.3133 10.988C81.3133 11.4235 81.4158 11.7768 81.6219 12.0487C81.8395 12.3384 82.1328 12.4833 82.4999 12.4833C82.8296 12.4833 83.0932 12.3627 83.2936 12.1207C83.4872 11.8973 83.5821 11.6282 83.5821 11.3142Z"
      fill="white"
    />
    <path
      d="M93.2144 10.9328C93.2144 11.6103 93.016 12.1655 92.6192 12.601C92.2032 13.0486 91.653 13.272 90.9638 13.272C90.3015 13.272 89.7733 13.058 89.3784 12.6281C88.9854 12.1991 88.7889 11.658 88.7889 11.0057C88.7889 10.3234 88.9912 9.76365 89.3976 9.32908C89.804 8.89451 90.3504 8.67676 91.0395 8.67676C91.7019 8.67676 92.2358 8.89077 92.6383 9.31973C93.0217 9.73654 93.2144 10.2748 93.2144 10.9328ZM92.1735 10.9646C92.1735 10.558 92.0834 10.2094 91.9041 9.91878C91.6923 9.56739 91.3932 9.39169 91.0021 9.39169C90.5996 9.39169 90.2928 9.56739 90.081 9.91878C89.9008 10.2094 89.8117 10.5636 89.8117 10.9823C89.8117 11.3888 89.9018 11.7374 90.081 12.0281C90.2986 12.3795 90.6015 12.5552 90.9926 12.5552C91.376 12.5552 91.6779 12.3767 91.8945 12.0187C92.0795 11.7225 92.1735 11.3711 92.1735 10.9646Z"
      fill="white"
    />
    <path
      d="M98.6155 13.1728H97.612V10.6495C97.612 9.87197 97.3091 9.4832 96.7004 9.4832C96.4023 9.4832 96.1617 9.58974 95.9758 9.80375C95.7898 10.0178 95.6968 10.2701 95.6968 10.5589V13.1719H94.6914V10.0262C94.6914 9.63927 94.6798 9.21965 94.6559 8.76546H95.5377L95.5847 9.45423H95.6125C95.7304 9.24021 95.9048 9.06358 96.133 8.92246C96.4061 8.75798 96.71 8.6748 97.0445 8.6748C97.4653 8.6748 97.8161 8.80751 98.095 9.07386C98.443 9.40002 98.6155 9.88692 98.6155 10.5336V13.1728Z"
      fill="white"
    />
    <path
      d="M105.376 9.50072H104.27V11.6408C104.27 12.1848 104.466 12.4567 104.855 12.4567C105.036 12.4567 105.185 12.4418 105.303 12.4109L105.329 13.1539C105.131 13.2268 104.87 13.2632 104.549 13.2632C104.152 13.2632 103.843 13.1455 103.62 12.91C103.396 12.6745 103.284 12.2782 103.284 11.7222V9.50072H102.624V8.76709H103.284V7.95963L104.269 7.66992V8.76616H105.375V9.50072H105.376Z"
      fill="white"
    />
    <path
      d="M110.694 13.1721H109.688V10.6674C109.688 9.87775 109.385 9.48243 108.779 9.48243C108.313 9.48243 107.995 9.7114 107.82 10.1693C107.79 10.2656 107.773 10.3833 107.773 10.5217V13.1711H106.77V6.74512H107.773V9.40019H107.792C108.109 8.91703 108.562 8.67591 109.15 8.67591C109.566 8.67591 109.91 8.80862 110.183 9.07496C110.523 9.40673 110.694 9.90018 110.694 10.5525V13.1721Z"
      fill="white"
    />
    <path
      d="M116.179 10.7614C116.179 10.9371 116.166 11.0848 116.142 11.2053H113.129C113.143 11.6408 113.287 11.9726 113.566 12.2025C113.821 12.4081 114.149 12.5109 114.552 12.5109C114.998 12.5109 115.404 12.4417 115.77 12.3025L115.927 12.9829C115.499 13.1642 114.995 13.2548 114.411 13.2548C113.711 13.2548 113.16 13.0539 112.761 12.652C112.361 12.2502 112.162 11.7109 112.162 11.0343C112.162 10.3698 112.347 9.81658 112.72 9.37547C113.109 8.90445 113.636 8.66895 114.3 8.66895C114.95 8.66895 115.443 8.90445 115.777 9.37547C116.046 9.74929 116.179 10.2119 116.179 10.7614ZM115.221 10.5082C115.229 10.2175 115.162 9.96704 115.026 9.75583C114.852 9.48388 114.586 9.34743 114.227 9.34743C113.899 9.34743 113.632 9.48014 113.428 9.74649C113.261 9.95863 113.162 10.2119 113.129 10.5082H115.221Z"
      fill="white"
    />
  </svg>
);
