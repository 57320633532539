import { ContentfulClientApi, createClient } from 'contentful';

import { contentful } from '../../constants/app';

if (!contentful.spaceId || !contentful.accessToken) {
  throw new Error('spaceId and accessToken are necessary to initialize Contentful Client');
}

export const client = createClient({
  space: contentful.spaceId,
  accessToken: contentful.accessToken,
});
