import { client } from './client';
import { ContentfulEntry, ContentfulSourceEntry } from './interfaces';
import { parseAssetUrl } from './utils';

/**
 * Returns one entry by id from Contentful
 * @param entryId - Entry id
 */
export const getEntryById = async (entryId: string): Promise<ContentfulEntry> => {
  const entry = await client.getEntry<ContentfulSourceEntry>(entryId);

  return {
    title: entry.fields.title,
    image: parseAssetUrl(entry.fields.image?.fields.file.url),
    content: entry.fields.content,
    author: entry.fields.author,
    authorLogo: parseAssetUrl(entry.fields.authorLogo?.fields.file.url),
    reviewer: entry.fields.reviewer,
  };
};
