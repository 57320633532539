import React from 'react';
import { css } from '@emotion/react';

import { BastionLogo } from '../icons/BastionLogo';
import { Author } from './Author';
import { Section, SectionAlign, SectionType } from './Section';
import { Separator } from './Separator';
import { Title } from './Title';
import { ShareButtons } from './ShareButtons';

interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  authorName: string;
  reviewerName: string;
  backgroundImage?: string;
  authorLogo?: string;
}

export const Header = (props: Props): JSX.Element => {
  const { title, authorName, reviewerName, backgroundImage, authorLogo } = props;

  return (
    <Section
      type={SectionType.BACKGROUND_IMAGE}
      src={backgroundImage}
      verticalAlign={SectionAlign.START}
    >
      <div
        className="container mt-8 mb-18 sm:mb-32 flex justify-center sm:justify-start"
        css={css`
          max-width: 1110px;
        `}
      >
        <BastionLogo />
      </div>
      <Author authorName={authorName} authorLogo={authorLogo} reviewerName={reviewerName} />
      <Separator width="120px" className="my-5" />
      <Title text={title} className="mb-18 sm:mb-32" />

      <div className="hidden md:flex absolute top-1/2 right-0 pr-4 transform -translate-y-1/2">
        <ShareButtons title={title} direction="col" />
      </div>
    </Section>
  );
};

Header.defaultProps = {
  backgroundImage: '',
  authorLogo: '',
};
