import React from 'react';
import { css } from '@emotion/react';

import colors from '../constants/colors.json';

interface ContentProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const Content = (props: ContentProps): JSX.Element => {
  return (
    <div
      className="leading-relaxed text-gray-500"
      css={css`
        max-width: 726px;

        ul,
        p {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        ul {
          & > li {
            &::before {
              content: '• ';
              color: ${colors.mustard[500]};
            }
          }
        }
      `}
      {...props}
    />
  );
};
