import React from 'react';
import classnames from 'classnames';
import { css } from '@emotion/react';

interface TitleProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  text: string;
}

export const Title = (props: TitleProps): JSX.Element => {
  const { text, className, ...otherProps } = props;
  const wrapperClass = classnames(
    'text-2xl',
    'sm:text-5xl',
    'text-white',
    'text-center',
    'leading-tight',
    'font-bold',
    className,
  );

  return (
    <h1
      className={wrapperClass}
      css={css`
        max-width: 828px;
      `}
      {...otherProps}
    >
      {text}
    </h1>
  );
};
